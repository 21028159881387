.proj1,
.proj2,
.proj3,
.proj4,
.proj5 {
    font-size: 25px;
    color: white;
    font-family: sans-serif;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.demo,
.repo,
.demo1 {
    font-family: sans-serif;
    color: #ffa629;
    border: #ffa629;
    border-radius: 10px;
    font-size: 20px;
    text-decoration: none;
    letter-spacing: 4px;

    &:hover {
        background: #ffa629;
        color: #333;
    }
}

@media screen and (max-width: 1200px) {
    
}