$primary-color: #ffa629;

@import 'animate.css';
@import '~loaders.css/src/animations/ball-clip-rotate-pulse.scss';

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

input, 
textarea {
  font-family: 'Helvetica Neue';
}

.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;

}

@media screen and (max-width: 1200px) {
  .loader-active {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    bottom: 50%;
  }
}