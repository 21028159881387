.home-page {
    
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }

    h1 {
        color:#fff;
        font-size: 53px;
        margin: 0;
        font-family: 'Helvetica Neue';
        font-weight: 400;

        &::before {
            font-family: 'La Belle Aurore';
            color: #ffa629;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }

        &::after {
            font-family: 'La Belle Aurore';
            color: #ffa629;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1.7s 2.4s backwards;
            opacity: 0.6;
        }

        img {
            width: 40px;
            height: 40px;
            opacity: 0;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
            
        }
    }

    h2 {
        color: #8d8d8e;
        margin-top: 20px;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #ffa629;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: #ffa629;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background: #ffa629;
            color: #333;
        }
    }

    .logo {
        z-index: 0;
        width: 400px;
        height: 400px;
        position: absolute;
        top: 0;
        right: 15%;
        bottom: 0;
        left: auto;
        margin: auto;
        animation: spin 10s linear infinite;
      }
      
      @keyframes spin {
        from {
          transform: rotate(360deg);
        }
        to {
          transform: rotate(0deg);
        }
      }
}

@media screen and (max-width: 1200px) {
    .tags,
    .home-page h1::before,
    .home-page h1::after {
        display: none;
    }

    .home-page {
        .text-zone {
            position: initial;
            width: 100%;
            transform: none;
            padding: 8px;
            box-sizing: border-box;
        }

        .flat-button {
            float: none;
            display: block;
            margin: 20px auto 0 auto;
            width: 125px;
        }

        h1 {
            font-size: 45px; /* Adjust the size as needed */
        }

        .logo {
            position: relative;
            width: 100px;
            height: auto;
            top: 50%;
            left: 37%;
            transform: translate(-50%, -50%);
        }
    }
}
