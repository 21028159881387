.title1,
.title2,
.title3,
.title0 {
    font-size: 22px;
    color: white;
    font-family: sans-serif;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.custom-button {
    position: fixed;
    top: 45%;
    right: 25%;
    padding: 10px 20px;
    font-family: sans-serif;
    font-size: 53px;
    text-decoration: none;
    letter-spacing: 4px;
    color: #ffa629;
    border: #ffa629;
    border-radius: 4px;
    box-shadow: 0 0 20px 0px lightblue;
    transition: all 0.3s ease-in-out;
  }

  .custom-button::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    transition: opacity 0.3s ease-in-out;
  }
  
  .custom-button:hover {
        background: #ffa629;
        color: #fff;
        box-shadow: 0 0 20px 0px #ffa629;
        transform: scale(1.2, 1.2);
  }

  @media screen and (max-width: 1200px) {
    .title1,
    .title2,
    .title3,
    .title0 {
        font-size: 20px;
        color: white;
        font-family: sans-serif;
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    .custom-button {
      position: relative;
      bottom: 20px;
      right: -12%;
      top: auto;
    }
  }